<template>
    <div class="row graphicBall">
        <div id="animationGraphicBalls" class="col-md-6 col-12">
            <div v-if="animation">
                <lottie :options="defaultOptions" v-on:animCreated="handleAnimation"/>
            </div>
        </div>
        <div class="col-md-6 col-12 table-graphic">
            <TableEC  :items="items" :rowHover="rowHover"/>
        </div>
    </div>
</template>

<script>
import TableEC from '@/web/components/TableEvaluatedCategories.vue'
import Lottie from './lottie.vue';
import dataGraphic from './animations/dataGraphicBalls.json';
import barramento from '@/barramento'

export default {
    props:['data'],
    components: {
        'lottie': Lottie,
        TableEC
    },
    data() {
        return {
            defaultOptions: Object,
            animController: Object,
            animation: false,
            animationJson: dataGraphic,  
            items: [],
            rowHover: ''
        }
    },
    created() {
        this.setData(this.data)
    },
    watch: {
        defaultOptions(){
            this.animation = true
        }
    },
    methods: {
        setData(items) {
            this.items = items;
            if(undefined != this.items[4])   
            {
                this.animationJson.assets[0].layers[0].t.d.k[0].s.t = this.formatNumber(this.percentCheck(this.items[4]['percent']))
                this.animationJson.assets[0].layers[1].t.d.k[0].s.t = this.items[4]['categoryCode']
            }
            if(undefined != this.items[3])   
            {
                this.animationJson.assets[1].layers[0].t.d.k[0].s.t = this.formatNumber(this.percentCheck(this.items[3]['percent']))
                this.animationJson.assets[1].layers[1].t.d.k[0].s.t = this.items[3]['categoryCode']
            }
            if(undefined != this.items[2])   
            {
                this.animationJson.assets[2].layers[0].t.d.k[0].s.t = this.formatNumber(this.percentCheck(this.items[2]['percent']))
                this.animationJson.assets[2].layers[1].t.d.k[0].s.t = this.items[2]['categoryCode']
            }
            if(undefined != this.items[1])   
            {
                this.animationJson.assets[3].layers[0].t.d.k[0].s.t = this.formatNumber(this.percentCheck(this.items[1]['percent']))
                this.animationJson.assets[3].layers[1].t.d.k[0].s.t = this.items[1]['categoryCode']
            }    
            if(undefined != this.items[0])   
            {
                this.animationJson.assets[4].layers[0].t.d.k[0].s.t = this.formatNumber(this.percentCheck(this.items[0]['percent']))
                this.animationJson.assets[4].layers[1].t.d.k[0].s.t = this.items[0]['categoryCode']
            }            
            if(undefined != this.items[9])   
            {
                this.animationJson.assets[5].layers[0].t.d.k[0].s.t = this.formatNumber(this.percentCheck(this.items[9]['percent']))
                this.animationJson.assets[5].layers[1].t.d.k[0].s.t = this.items[9]['categoryCode']
            }
            if(undefined != this.items[8])   
            {
                this.animationJson.assets[6].layers[0].t.d.k[0].s.t = this.formatNumber(this.percentCheck(this.items[8]['percent']))
                this.animationJson.assets[6].layers[1].t.d.k[0].s.t = this.items[8]['categoryCode']
            }
            if(undefined != this.items[7])   
            {
                this.animationJson.assets[7].layers[0].t.d.k[0].s.t = this.formatNumber(this.percentCheck(this.items[7]['percent']))
                this.animationJson.assets[7].layers[1].t.d.k[0].s.t = this.items[7]['categoryCode']
            }
            if(undefined != this.items[6])   
            {
                this.animationJson.assets[8].layers[0].t.d.k[0].s.t = this.formatNumber(this.percentCheck(this.items[6]['percent']))
                this.animationJson.assets[8].layers[1].t.d.k[0].s.t = this.items[6]['categoryCode']
            }
            if(undefined != this.items[5])   
            {
                this.animationJson.assets[9].layers[0].t.d.k[0].s.t = this.formatNumber(this.percentCheck(this.items[5]['percent']))
                this.animationJson.assets[9].layers[1].t.d.k[0].s.t = this.items[5]['categoryCode']
            }
            this.defaultOptions = {animationData: this.animationJson, loop: false}
        },
        handleAnimation(anim) {
            anim.addEventListener("complete", function() {
                this.addClickEventsHandler();
            }.bind(this))
            this.anim = anim;
                anim.playSegments([0, 50], true)
        },
        percentCheck(val) {
            if(!val){
                return '0%'
            }
            if (val == 0){
                return '0%'
            } else if(Number.isInteger(val)) {
                return val + '.0%'
            } else {
                return val + '%'
            }
        },
        addClickEventsHandler() {
            var t = this
            //Extinta (EX)
            document.getElementById("bolaPreto").parentElement.getElementsByTagName("text").forEach(function(el) { 
                el.style.cursor = "pointer"
                el.addEventListener("click", function() {
                    barramento.$emit('openModalR', {'query': "?categoriaIds=127", 'items': {'categoriaIds': [127]}, 'filter': 'Extinta (EX)'})
                });
                el.addEventListener("mouseover", function(){
                    t.rowHover = 'EX'
                });
                el.addEventListener("mouseout", function(){
                    t.rowHover = ''
                });
            })
            document.getElementById("bolaPreto").style.cursor = "pointer"
            document.getElementById("bolaPreto").addEventListener("click", function() {
                barramento.$emit('openModalR', {'query': "/search?categoriaIds=127", 'items': {'categoriaIds': [127]}, 'filter': 'Extinta (EX)'});
                
            });
            document.getElementById("bolaPreto").addEventListener("mouseover", function(){
                t.rowHover = 'EX'
            });
            document.getElementById("bolaPreto").addEventListener("mouseout", function(){
                t.rowHover = ''
            });
            //Extinta na Natureza (EW)
            document.getElementById("bolaRoxo").parentElement.getElementsByTagName("text").forEach(function(el) { 
                el.style.cursor = "pointer"
                el.addEventListener("click", function() {
                    barramento.$emit('openModalR', {'query': "?categoriaIds=128", 'items': {'categoriaIds': [128]}, 'filter': 'Extinta na Natureza (EW)'})
                });
                el.addEventListener("mouseover", function(){
                    t.rowHover = 'EW'
                });
                el.addEventListener("mouseout", function(){
                    t.rowHover = ''
                });
            })
            document.getElementById("bolaRoxo").style.cursor = "pointer"
            document.getElementById("bolaRoxo").addEventListener("click", function() {
                barramento.$emit('openModalR', {'query': "?categoriaIds=128", 'items': {'categoriaIds': [128]}, 'filter': 'Extinta na Natureza (EW)'})
            });
            document.getElementById("bolaRoxo").addEventListener("mouseover", function(){
                t.rowHover = 'EW'
            });
            document.getElementById("bolaRoxo").addEventListener("mouseout", function(){
                t.rowHover = ''
            });
            //Regionalmente Extinta (RE)
            document.getElementById("bolaLilas").parentElement.getElementsByTagName("text").forEach(function(el) { 
                el.style.cursor = "pointer"
                el.addEventListener("click", function() {
                    barramento.$emit('openModalR', {'query': "?categoriaIds=129", 'items': {'categoriaIds': [129]}, 'filter': 'Regionalmente Extinta (RE)'})
                });
                el.addEventListener("mouseover", function(){
                    t.rowHover = 'RE'
                });
                el.addEventListener("mouseout", function(){
                    t.rowHover = ''
                });
            })
            document.getElementById("bolaLilas").style.cursor = "pointer"
            document.getElementById("bolaLilas").addEventListener("click", function() {
                barramento.$emit('openModalR', {'query': "?categoriaIds=129", 'items': {'categoriaIds': [129]}, 'filter': 'Regionalmente Extinta (RE)'})
            });
            document.getElementById("bolaLilas").addEventListener("mouseover", function(){
                t.rowHover = 'RE'
            });
            document.getElementById("bolaLilas").addEventListener("mouseout", function(){
                t.rowHover = ''
            });
            //Criticamente em perigo (CR)
            document.getElementById("bolaVermelho").parentElement.getElementsByTagName("text").forEach(function(el) { 
                el.style.cursor = "pointer"
                el.addEventListener("click", function() {
                    barramento.$emit('openModalR', {'query': "?categoriaIds=130", 'items': {'categoriaIds': [130]}, 'filter': 'Criticamente em Perigo (CR)'})
                });
                el.addEventListener("mouseover", function(){
                    t.rowHover = 'CR'
                });
                el.addEventListener("mouseout", function(){
                    t.rowHover = ''
                });
            })
            document.getElementById("bolaVermelho").style.cursor = "pointer"
            document.getElementById("bolaVermelho").addEventListener("click", function() {
                barramento.$emit('openModalR', {'query': "?categoriaIds=130", 'items': {'categoriaIds': [130]}, 'filter': 'Criticamente em Perigo (CR)'})
            });
            document.getElementById("bolaVermelho").addEventListener("mouseover", function(){
                t.rowHover = 'CR'
            });
            document.getElementById("bolaVermelho").addEventListener("mouseout", function(){
                t.rowHover = ''
            });
            //Em perigo (EN)
            document.getElementById("bolaLaranja").parentElement.getElementsByTagName("text").forEach(function(el) { 
                el.style.cursor = "pointer"
                el.addEventListener("click", function() {
                    barramento.$emit('openModalR', {'query': "?categoriaIds=131", 'items': {'categoriaIds': [131]}, 'filter': 'Em Perigo (EN)'})
                });
                el.addEventListener("mouseover", function(){
                    t.rowHover = 'EN'
                });
                el.addEventListener("mouseout", function(){
                    t.rowHover = ''
                });
            })
            document.getElementById("bolaLaranja").style.cursor = "pointer"
            document.getElementById("bolaLaranja").addEventListener("click", function() {
                barramento.$emit('openModalR', {'query': "?categoriaIds=131", 'items': {'categoriaIds': [131]}, 'filter': 'Em Perigo (EN)'})
            });
            document.getElementById("bolaLaranja").addEventListener("mouseover", function(){
                t.rowHover = 'EN'
            });
            document.getElementById("bolaLaranja").addEventListener("mouseout", function(){
                t.rowHover = ''
            });
            //Vulnerável (VU)
            document.getElementById("bolaAmarelo").parentElement.getElementsByTagName("text").forEach(function(el) { 
                el.style.cursor = "pointer"
                el.addEventListener("click", function() {
                    barramento.$emit('openModalR', {'query': "?categoriaIds=132", 'items': {'categoriaIds': [132]}, 'filter': 'Vulnerável (VU)'})
                });
                el.addEventListener("mouseover", function(){
                    t.rowHover = 'VU'
                });
                el.addEventListener("mouseout", function(){
                    t.rowHover = ''
                });
            })
            document.getElementById("bolaAmarelo").style.cursor = "pointer"
            document.getElementById("bolaAmarelo").addEventListener("click", function() {
                barramento.$emit('openModalR', {'query': "?categoriaIds=132", 'items': {'categoriaIds': [132]}, 'filter': 'Vulnerável (VU)'})
            });
            document.getElementById("bolaAmarelo").addEventListener("mouseover", function(){
                t.rowHover = 'VU'
            });
            document.getElementById("bolaAmarelo").addEventListener("mouseout", function(){
                t.rowHover = ''
            });
            //Quase Ameaçada (NT)
            document.getElementById("bolaVerdeClaro").parentElement.getElementsByTagName("text").forEach(function(el) { 
                el.style.cursor = "pointer"
                el.addEventListener("click", function() {
                    barramento.$emit('openModalR', {'query': "?categoriaIds=133", 'items': {'categoriaIds': [133]}, 'filter': 'Quase Ameaçada (NT)'})
                });
                el.addEventListener("mouseover", function(){
                    t.rowHover = 'NT'
                });
                el.addEventListener("mouseout", function(){
                    t.rowHover = ''
                });
            })
            document.getElementById("bolaVerdeClaro").style.cursor = "pointer"
            document.getElementById("bolaVerdeClaro").addEventListener("click", function() {
                barramento.$emit('openModalR', {'query': "?categoriaIds=133", 'items': {'categoriaIds': [133]}, 'filter': 'Quase Ameaçada (NT)'})
            });
            document.getElementById("bolaVerdeClaro").addEventListener("mouseover", function(){
                t.rowHover = 'NT'
            });
            document.getElementById("bolaVerdeClaro").addEventListener("mouseout", function(){
                t.rowHover = ''
            });
            //Menos preocupante (LC)
            document.getElementById("bolaVerde").parentElement.getElementsByTagName("text").forEach(function(el) { 
                el.style.cursor = "pointer"
                el.addEventListener("click", function() {
                    barramento.$emit('openModalR', {'query': "/search?categoriaIds=134", 'items': {'categoriaIds': [134]}, 'filter': 'Menos Preocupante (LC)'})
                });
                el.addEventListener("mouseover", function(){
                    t.rowHover = 'LC'
                });
                el.addEventListener("mouseout", function(){
                    t.rowHover = ''
                });
            })
            document.getElementById("bolaVerde").style.cursor = "pointer"
            document.getElementById("bolaVerde").addEventListener("click", function() {
                barramento.$emit('openModalR', {'query': "?categoriaIds=134", 'items': {'categoriaIds': [134]}, 'filter': 'Menos Preocupante (LC)'})
            });
            document.getElementById("bolaVerde").addEventListener("mouseover", function(){
                t.rowHover = 'LC'
            });
            document.getElementById("bolaVerde").addEventListener("mouseout", function(){
                t.rowHover = ''
            });
            //Dados Insuficientes (DD)
            document.getElementById("bolaBegeClaro").parentElement.getElementsByTagName("text").forEach(function(el) { 
                el.style.cursor = "pointer"
                el.addEventListener("click", function() {
                    barramento.$emit('openModalR', {'query': "?categoriaIds=135", 'items': {'categoriaIds': [135]}, 'filter': 'Dados Insuficientes (DD)'})
                });
                el.addEventListener("mouseover", function(){
                    t.rowHover = 'DD'
                });
                el.addEventListener("mouseout", function(){
                    t.rowHover = ''
                });
            })
            document.getElementById("bolaBegeClaro").style.cursor = "pointer"
            document.getElementById("bolaBegeClaro").addEventListener("click", function() {
                barramento.$emit('openModalR', {'query': "?categoriaIds=135", 'items': {'categoriaIds': [135]}, 'filter': 'Dados Insuficientes (DD)'})
            });
            document.getElementById("bolaBegeClaro").addEventListener("mouseover", function(){
                t.rowHover = 'DD'
            });
            document.getElementById("bolaBegeClaro").addEventListener("mouseout", function(){
                t.rowHover = ''
            });
            //Não Aplicável (NA)
            document.getElementById("bolaBege").parentElement.getElementsByTagName("text").forEach(function(el) { 
                el.style.cursor = "pointer"
                el.addEventListener("click", function() {
                    barramento.$emit('openModalR', {'query': "?categoriaIds=136", 'items': {'categoriaIds': [136]}, 'filter': 'Não Aplicável (NA)'})
                });
                el.addEventListener("mouseover", function(){
                    t.rowHover = 'NA'
                });
                el.addEventListener("mouseout", function(){
                    t.rowHover = ''
                });
            })
            document.getElementById("bolaBege").style.cursor = "pointer"
            document.getElementById("bolaBege").addEventListener("click", function() {
                barramento.$emit('openModalR', {'query': "/search?categoriaIds=136", 'items': {'categoriaIds': [136]}, 'filter': 'Não Aplicável (NA)'})
            }); 
            document.getElementById("bolaBege").addEventListener("mouseover", function(){
                t.rowHover = 'NA'
            });
            document.getElementById("bolaBege").addEventListener("mouseout", function(){
                t.rowHover = ''
            });           
        }
    }
}
</script>

